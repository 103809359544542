import React, { useRef, useState, useEffect } from "react"
import {
  StencilCircles,
  Article,
  Heading,
  Paragraph,
  StackedTriangles,
  FanningTriangles,
} from "../components"
import tw from "tailwind-styled-components"
import { PortfolioItem, PiList, ScrollTop } from "../components"

const PiLink = tw.a`
    text-cyan
    underline
  `

const Portfolio: React.FC = () => {
  const [visible, setVisible] = useState(false) // is scroll to top button visible?

  const ref = useRef<HTMLHeadingElement>(null) // element that triggers visibility change

  // set visibility state based on whether viewport is past an element
  const obsCallback: IntersectionObserverCallback = (entries) => {
    // coerce to any because generic base class is used for target
    const [entry] = entries as any

    setVisible(window.scrollY > entry.target.offsetTop)
  }

  useEffect(() => {
    // create observer
    const obs = new IntersectionObserver(obsCallback)

    // store ref to ensure hook runs on the right object
    const current = ref.current

    // start observin'
    if (current) {
      obs.observe(current)
    }

    // stop observin' when component unmounts
    return () => {
      if (current) {
        obs.unobserve(current)
      }
    }
  }, [ref])

  return (
    <>
      <Article>
        <StencilCircles />
        <Heading>Portfolio</Heading>
        <Paragraph>
          Here is a list of some of the work that I have done in last couple
          years, with a description of each project, and an overview of the
          tools that I used to build them. This list is in reverse chronological
          order, with the most recent projects at the top.
        </Paragraph>
        <StackedTriangles />
        <PortfolioItem
          name="This site"
          tools={["Gatsby.js", "Storybook", "tailwindcss", "THREE.js", "React"]}
        >
          <Paragraph>
            A personal portfolio site made using React and Gatsby.js.
          </Paragraph>
          <Paragraph>
            I used{" "}
            <PiLink
              href="https://threejs.org/"
              target="_blank"
              rel="noreferrer"
            >
              THREE.js
            </PiLink>{" "}
            to make the 3d animated landing page. Everything else was made
            directly with the HTML canvas API.
          </Paragraph>
          <Paragraph>
            The source code for this site is public. You can see it on my Github
            profile at:{" "}
            <PiLink
              className="text-cyan underline"
              rel="noreferrer"
              href="https://github.com/baremaximum/portfolio-site"
              target="_blank"
            >
              https://github.com/baremaximum/portfolio-site
            </PiLink>
          </Paragraph>
        </PortfolioItem>
        <FanningTriangles />
        <PortfolioItem
          name="OpenFaas - Rust actix-web template"
          tools={["Kubernetes", "rust-actix-web", "OpenFaas"]}
        >
          <Paragraph ref={ref}>
            A template for creating OpenFaas functions using the rust-actix-web
            framework.
          </Paragraph>
          <Paragraph>
            OpenFaas is a framework for creating, deploying, and managing cloud
            functions on your own Kubernetes cluster. It uses a template system
            to make it easier for users to create functions using the language
            or framework of their choice, without having to do any generic
            setup.
          </Paragraph>
          <Paragraph>
            I made this template because there was no usable HTTP based template
            for Rust. There was one on the store, but there were serious
            problems with it, and it didn't even compile. So I made one.
          </Paragraph>
          <Paragraph>
            I chose to use the actix-web framework for this template because
            it's ideal for creating function endpoints. It's feature rich,
            performance metrics are as good as it gets, the compiled binaries
            are tiny, and the servers have a start-up time of less than a
            millisecond, making them ideal for rapid on demand scaling.
          </Paragraph>
          <Paragraph>
            Source code:{" "}
            <PiLink
              href="https://github.com/baremaximum/rust-actix-web-template"
              rel="noreferrer"
              target="_blank"
            >
              https://github.com/baremaximum/rust-actix-web-template
            </PiLink>
          </Paragraph>
        </PortfolioItem>
        <StackedTriangles />
        <PortfolioItem
          name="Naloxone Map"
          tools={[
            "React",
            "Redis",
            "MongoDB",
            "Jest",
            "Cypress",
            "Kubernetes",
            "Angular",
            "Karma",
            "Redux/ngrx",
            "Nest.js",
            "Fastify",
            "Istio",
            "Letsencrypt/certbot",
            "Google Maps API",
            "Google Places API",
            "Google Container Registry",
            "Google Kubernetes Engine",
            "Github Actions",
          ]}
        >
          <Paragraph>
            A platform for collecting and maintaining data about publicly
            available naloxone kits, with a site that allows people to search
            that data and see it on a map.
          </Paragraph>
          <Paragraph>
            I built this application as a personal challenge and learning
            opportunity. I wanted to take advantage of the free time I had
            during the Covid pandemic to learn more about several tools I was
            interested in, and get better at designing and building microservice
            architectures.
          </Paragraph>
          <Paragraph>For this project, I built or configured:</Paragraph>
          <PiList>
            <li>Two front end applications</li>
            <li>Two rest APIs</li>
            <li>A Redis instance</li>
            <li>A MongoDB database</li>
            <li>A Kubernetes cluster with an Istio mesh</li>
            <li>
              A CI/CD pipeline, including test runners, as well as image
              building, storage and deployment
            </li>
          </PiList>
          <Paragraph>
            The application is offline at the moment since running a Kubernetes
            cluster is expensive. But it's complete, and could be re-deployed at
            a moment's notice. I'm currently searching for an organization that
            would want to put it to use.
          </Paragraph>
        </PortfolioItem>
        <FanningTriangles />
        <PortfolioItem name="Weather data API" tools={["Dajngo", "Python"]}>
          <Paragraph>
            I helped build and maintain a Django API that cached, serialized,
            and served weather data to digital signage clients around the world.
          </Paragraph>
        </PortfolioItem>
        <StackedTriangles />
        <PortfolioItem
          name="Internal development tools and libraries"
          tools={[
            "Python",
            "ScalaScript (no relation to the Scala programming language)",
          ]}
        >
          <Paragraph>
            I worked extensiely on internal company python libraries. I did a
            bit of everything, from debugging, to adding unit tests, refactoring
            and cleaning up old code, to developing new features and modules.
          </Paragraph>
          <Paragraph>
            I also made a tool for parsing and applying routine transofrmations
            to scripts that previously were being done by hand.
          </Paragraph>
        </PortfolioItem>
        <FanningTriangles />
        <PortfolioItem
          name="Digital Signage Scripts"
          tools={[
            "Python",
            "ScalaScript (no relation to the Scala programming language)",
            "Raw HTML/CSS/JavaScript",
          ]}
        >
          <Paragraph>
            Worked closely with a design team to create custom digital signage
            scripts for corporate clients located around the world.
          </Paragraph>
          <Paragraph>
            These scripts were made with a combination of Python, HTML/JS, and a
            proprietary scripting language called ScalaScript
          </Paragraph>
        </PortfolioItem>
      </Article>
      {visible && <ScrollTop />}
    </>
  )
}

export default Portfolio
